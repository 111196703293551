'use client';

import Button from '@/components/molecules/button';
import TextInput from '@/components/molecules/text-input';
import { useAppState } from '@/components/providers/state-provider';
import { usePathname } from 'next/navigation';
import { FormEvent, useState } from 'react';

export interface CcList {
  id: string;
  status: string;
}

export interface CcEmailAddress {
  id: string;
  status: string;
  confirm_status: string;
  opt_in_source: string;
  opt_in_date: string;
  email_address: string;
}

export interface CcSuccess {
  id: string;
  status: string;
  fax: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addresses: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes: any[];
  confirmed: boolean;
  lists: CcList[];
  source: string;
  email_addresses: CcEmailAddress[];
  prefix_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  job_title: string;
  company_name: string;
  home_phone: string;
  work_phone: string;
  cell_phone: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  custom_fields: any[];
  created_date: string;
  modified_date: string;
  source_details: string;
}

export interface CcError {
  error_key: string;
  error_message: string;
}

export default function Footer() {
  const {
    state: { isLoading, user },
  } = useAppState();

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError('');
    setSuccess('');

    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/constant-contact`,
      {
        method: 'POST',
        body: JSON.stringify({
          firstName,
          lastName,
          email,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        cache: 'no-store',
        next: {
          tags: ['all', 'constant-contact'],
        },
      },
    );

    const data = (await res.json()) as CcError[] | CcSuccess;

    if (Array.isArray(data)) {
      setError(data[0].error_message);
    } else {
      setSuccess('You have successfully been added to our weekly newsletter.');
    }
  };

  const path = usePathname();

  if (
    path === '/user/signup' ||
    path.startsWith('/user/signup/') ||
    path === '/user/login' ||
    path.startsWith('/user/login/')
  ) {
    return <div className='h-96'></div>;
  }

  if (path.endsWith('/print')) {
    return null;
  }

  return (
    <>

      <div id="subscribe">
      {!isLoading && !user && (
            <form
          className='mx-auto grid max-w-xl grid-cols-2 gap-4 border border-neutral-300 p-4'
          onSubmit={submit}
        >
          <h3 className='col-span-2 text-xl font-bold'>
            Get FREE sermon prep tools and exclusive content, inlcuding our famous weekly ILLUSTRATIONS NEWSLETTER, delivered straight
            to your inbox
          </h3>
          <div className='col-span-2'>
            Sign up is easy and you can stop any time.
          </div>
          {error && <div className='col-span-2 text-red-500'>{error}</div>}
          {success && (
            <div className='col-span-2 text-green-500'>{success}</div>
          )}
          <TextInput
            className='w-full'
            id='newsletterFirstName'
            label='First Name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextInput
            className='w-full'
            id='newsletterLastName'
            label='Last Name'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <div className='col-span-2'>
            <TextInput
              className='w-full'
              id='newsletterEmail'
              label='Email'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button className='col-span-2' type='submit'>
            Subscribe
          </Button>
        </form>
      )}
      </div>

    <div className='h-96'></div>
    </>
  );
}
